import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import RenderTags from "../components/RenderTags"
import kebabCase from "lodash/kebabCase"

const tagTemplate = ({ pageContext, data }) => {

    const { tag } = pageContext
    const { totalCount } = data.allMarkdownRemark
    const listtags = data.allMarkdownRemark.edges
    const { html } = data.markdownRemark
    const { group } = data.group;

    return (
        <Layout>
            <div className="main-content-padding">
                <div className="app-general-seciton app-section-blogs-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={5}>
                                <div 
                                    className="app-section-blogs-introduction-detail" 
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            </Col>
                            <Col xs={12} md={12} lg={7}></Col>
                        </Row>
                    </Container>
                </div>
                <div className="app-general-seciton app-blogs-detail-topic-search" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={1}></Col>
                            <Col xs={12} md={12} lg={9}>
                                <div className="app-blog-posts-directory">
                                    <h6><Link to="/support/blogs">Blog Home</Link></h6>
                                    <img src="/app-icon-blog-posts-directory.svg" alt="app icon blog posts directory img" />
                                    <h6>{tag}</h6>
                                </div>
                                <h2>Topic: {tag}</h2>
                                <h6>Showing {totalCount} results</h6>
                                {listtags.map(({ node }) => (
                                    <Row className="app-blogs-detail-topic-search-row-custom" key={node.frontmatter.slug}>
                                        <Col xs={12} md={5} lg={5} xl={3} className="app-blogs-detail-topic-search-col-custom">
                                            <div className="app-blogs-detail-topic-search-left">
                                                <Link to={"../../support/blogs/" + node.frontmatter.slug}>
                                                    <Img
                                                        fluid={
                                                            node.frontmatter
                                                                .thumb
                                                                .childImageSharp
                                                                .fluid
                                                        }
                                                    />
                                                </Link>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={7} lg={7} xl={9} className="app-blogs-detail-topic-search-col-custom">
                                            <div className="app-blogs-detail-topic-search-right">
                                                <Link to={"../../support/blogs/" + node.frontmatter.slug}>
                                                    {node.frontmatter.title}
                                                </Link>
                                                <h6>{node.frontmatter.description}</h6>
                                                <div className="app-blogs-topic-search-infor-author-calendar-related-article">
                                                    <div className="app-blogs-topic-search-infor-author-calendar">
                                                        <div className="app-blogs-topic-search-right-author">
                                                            <img src="/app-icon-user.svg" alt="app icon user"/>
                                                            <h6>{node.frontmatter.author}</h6>
                                                        </div>
                                                        <div className="app-blogs-topic-search-right-calendar">
                                                            <img src="/app-icon-calendar.svg" alt="app icon calendar"/>
                                                            <h6>{node.frontmatter.publishedAt}</h6>
                                                        </div>
                                                    </div>
                                                    {/* <div className="app-blogs-topic-search-related-article">
                                                        <img src="/app-blogs-topic-search-right-tag.svg" alt="app blogs topic search right tag" />
                                                        <div className="app-blogs-topic-search-related-article-tag">
                                                            {(node.frontmatter.tags).map((res) => (
                                                                <Link className="app-blogs-topic-search-related-article-link" to={`/tags/${kebabCase(res)}/`} key={res}>{res}</Link>
                                                            ))}
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                                {/* <h6 className="app-blogs-detail-topic-search-show-more">Show more</h6> */}
                            </Col>
                            <Col xs={12} md={12} lg={2}>
                                <RenderTags group={group} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </Layout>
    )
}

export default tagTemplate

export const pageQuery = graphql`
    query TagTemplate($tag: String) {
        allMarkdownRemark(
            filter: { frontmatter: { tags: { in: [$tag] } }, fields: { draft: { eq: false } } }
            sort: {fields: frontmatter___publishedAt, order: ASC}
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        slug
                        description
                        author
                        publishedAt(formatString: "MMM Do, YYYY")
                        tags
                        thumb {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
            totalCount
        }
        group: allMarkdownRemark(
            filter: { fields: { draft: { eq: false } } }
        ) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
        markdownRemark(
            frontmatter: { directory: { eq: "blogs_introduction" } }
        ) {
            html
            id
        }
    }
`